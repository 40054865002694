html.no-touch {margin-top: 0 !important;}
html.open {margin-top: 0 !important;}
html {-webkit-text-size-adjust: 100%;-ms-text-size-adjust: 100%;overflow-x: hidden;}
body {margin: 0; padding: 0; font-size: 22px; background-color: #fff; font-style: normal;font-family: "brandon-grotesque";font-weight: 500;}
a{text-decoration: none; outline: none; -webkit-transition: all ease-in-out 0.2s; transition: all ease-in-out 0.2s; }
a:hover,a:focus{outline: none; text-decoration: none;}
:focus {outline: none;}
b, strong{ font-weight: 700; }

html {min-height: 100%;position: relative;overflow-x: hidden;}
body {height: 100%;}
.site-footer {bottom: 0;left: 0;position: absolute;right: 0;top: 100%;}

/*# Global*/
/*typo*/
h1, .h1 { font-size: 45px; line-height: normal;color: #0F3C70;font-family: "basic-sans";font-weight: 700;}
h2, .h2 { font-size: 38px; line-height: normal;color: #0F3C70;font-family: "basic-sans";font-weight: 700;}
h3, .h3 { font-size: 35px; line-height: normal;color: #0F3C70;font-family: "basic-sans";font-weight: 700;}
h4, .h4 { font-size: 33px; line-height: normal;color: #0F3C70;font-family: "basic-sans";font-weight: 700;}
h5, .h5 { font-size: 30px; line-height: normal;color: #0F3C70;font-family: "basic-sans";font-weight: 700;}
h6, .h6 { font-size: 26px; line-height: normal;color: #0F3C70;font-family: "basic-sans";font-weight: 700;}

blockquote p:last-child, blockquote ul:last-child, blockquote ol:last-child {margin: 0;}

/*Button*/
.btn{white-space: normal; border-radius: 0;border: none; padding: 14px 32px 13px 32px; font-size: 22px; position: relative; box-shadow: none; color: #fff;font-style: normal; -webkit-transition: all 0.3s; transition: all 0.3s;letter-spacing: normal;font-family: "brandon-grotesque";}
.btn:hover,.btn:focus{outline: none;}
.btn:focus{ box-shadow: none; }
.btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus{outline: none; box-shadow: none;}

/*btn default*/
.btn-default {background-color: #D0021B;}
.btn-default:hover,.btn-default:focus,.btn-default:active, .btn-default.active, .open>.dropdown-toggle.btn-default{background-color: #ff3a53; color: #fff;}
.btn-default:active:hover, .btn-default.active:hover, .open>.dropdown-toggle.btn-default:hover, .btn-default:active:focus, .btn-default.active:focus, .open>.dropdown-toggle.btn-default:focus, .btn-default:active.focus, .btn-default.active.focus, .open>.dropdown-toggle.btn-default.focus{background-color: #D0021B; color: #fff; outline: none;}

/*btn secondary*/
.btn-secondary { background-color: transparent; color: #d8f5ff; border: 1px solid #d8f5ff; min-height: 51px;}
.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active {background: rgba(0, 0, 0, 0) none repeat scroll 0 0;border-color: rgba(216, 245, 255, 0.5);color: #d8f5ff;text-shadow: 0 0 10px rgba(255, 255, 255, 0.7);}



/*link*/
a.link {font-size: 13px;color: #28323d;}
/*date*/
date {color: #666666; font-size: 13px; letter-spacing: 0.05em; font-weight: 600; text-transform: uppercase;}
/*wp col*/
.one-half.first {float: left;padding-right: 15px;width: 50%; padding-left: 0;}
.one-half { width: 50%; float: left; padding-left: 15px;}
/*image*/
img { max-width: 100%; height: auto; }
.img-hide { opacity: 0; height: 100%; width: 100%; }
.img-cover { background-repeat: no-repeat; background-size: cover; background-position: center center; display: block; }
.img-cover img {opacity: 0;visibility: hidden;width: 100%;height: auto;}
.link-over {height: 100%;left: 0;position: absolute;top: 0;width: 100%;z-index: 1;}
.padding-0 {padding: 0;}


/*--------------------------------------------------------------
# Alignments
--------------------------------------------------------------*/
.alignleft {float: left;margin-bottom: 15px;margin-right: 15px;}
.aligncenter {display: block;float: none;margin: 15px auto;}
.alignright {float: right;margin-bottom: 15px;margin-left: 15px;}

.inner-page-content ol {margin: 0 0 20px;padding: 0 0 0 18px;}
.inner-page-content ol li {list-style-position: outside;padding-left: 10px;font-size: 19px;}

.inner-page-content ul {margin: 0 0 20px;padding: 0 0 0 18px;}
.inner-page-content ul li {list-style-position: outside;padding-left: 10px;font-size: 19px;}


/*================================================================
# Header
================================================================*/
.site-header {background-color: #373737;display: inline-block;padding: 18px 0 31px;width: 100%;}
.header-logo {float: left;padding-left: 16px;padding-top: 15px;width: 39%;}
.header-nav {float: left;width: 45%;}
.header-call-info {float: left;width: 15%;}
.logo {max-width: 461px;position: relative;}
.logo h1 {color: #ffffff;font-family: "basic-sans";font-size: 40px;font-weight: bold;margin-bottom: 4px;}
.logo p {color: #d0021b;font-size: 21px;overflow: hidden;position: relative;text-align: right;text-transform: uppercase;font-family: 'brandon-grotesque';}
.logo p span {display: inline-block;padding-left: 20px;position: relative;width: auto;}
.logo p span:after {background-color: #d0021b;
    content: "";
    height: 2px;
    margin-left: 20px;
    position: absolute;
    right: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    width: 1000px;}


/*--------------------------------------------------------------
## Menus
--------------------------------------------------------------*/


/* MENU */
.menu-icon {display: none;}
.main-navigation {display: inline-block;margin-left: 12px;margin-top: 30px;}
.main-navigation ul {display: inline;margin: 0;padding: 0;}
.main-navigation ul li {}
.main-navigation ul li:before{display: none;}
.menu > li{ float: left; margin: 0; position: relative;list-style: none;}

.menu > li:hover > a:after, .menu > li.current-menu-item a:after, .menu > li.current-page-parent a:after, .menu > li.current_page_parent a:after, .menu >li.current-page-ancestor a:after, .menu > li.current-menu-ancestor a:after, .menu > li.current-menu-parent a:after { width: 100%;}
.touch .menu > li:hover > a:after{ display: none;}
.menu > li:first-child{ padding-left: 0;}
.menu > li:last-child{ padding-right: 0;}
.menu > li > a {color: #ffffff;font-size: 21px;padding: 0 22px 30px;font-family: 'brandon-grotesque';font-weight: 500;}
.menu > li > a:hover {color: #d0021b;}
/* SUB MENU */
.menu li.menu-item-has-children:hover .sub-menu, .menu li.menu-item-has-children.hover .sub-menu { opacity: 1; visibility: visible;}
.sub-menu { background-color: #fff; position: absolute; top: 100%; left: -120px; width: 360px; opacity: 0; visibility: hidden; -webkit-transition: all 0.2s ease 0s; transition: all 0.2s ease 0s;}
.menu li.menu-item-has-children .sub-menu {top: 188%;}
.menu li.menu-item-has-children .sub-menu li.menu-item-has-children .sub-menu {padding-top: 0;}

.sub-menu a{ color: #494949; display: block; padding: 31px 15px 31px 70px; font-family: "brandon-grotesque"; position: relative;}

.sub-menu a:hover, .sub-menu li.current-menu-item a{ color: #d0021b;}
.sub-menu a:hover:before, .sub-menu li.current-menu-item a:before{ opacity: 1;}

.main-navigation .sub-menu .sub-menu {display: none;}
.main-navigation .sub-menu li {padding: 0;}
.main-navigation .sub-menu li.menu-item-has-children:hover .sub-menu {position: absolute;top: 0;left: 100%;display: block;}
.main-navigation .sub-menu li.menu-item-has-children {position: relative;}
.main-navigation .sub-menu li.menu-item-has-children a:after {color: #fff;content: "\f105";font-family: fontawesome;font-size: 18px;position: absolute;right: 10px;top: 46%;-webkit-transform: translateY(-50%);transform: translateY(-50%);width: auto;}
.main-navigation .sub-menu li.menu-item-has-children:hover a {color: #d0021b;}
.main-navigation .sub-menu li.menu-item-has-children:hover a:after {color: #d0021b;}
.main-navigation .sub-menu li.menu-item-has-children .sub-menu li a:after {display: none;}
.main-navigation .sub-menu li.menu-item-has-children:hover .sub-menu li a {background-color: #fff;color: #fff;}
.main-navigation .sub-menu li.menu-item-has-children a:before {opacity: 1;}
.main-navigation .sub-menu li.menu-item-has-children:hover .sub-menu li a:hover {color: #d0021b;}

.main-navigation .sub-menu li.menu-item-has-children.current-menu-item a:after {color: #d0021b;}

.sub-menu li.current-menu-ancestor a, .sub-menu li.current-menu-parent a, .sub-menu li.current_page_parent a, .sub-menu li.current_page_ancestor a {color: #d0021b;}
.sub-menu li.menu-item-has-children.current-menu-ancestor a:after, .sub-menu li.menu-item-has-children.current-menu-parent a:after, .sub-menu li.menu-item-has-children.current_page_parent a:after, .sub-menu li.menu-item-has-children.current_page_ancestor a:after {color: #d0021b;}
.main-navigation .sub-menu li.current-menu-ancestor .sub-menu li.current-menu-item a  {color: #d0021b;}
.main-navigation .sub-menu li.current-menu-parent .sub-menu li.current-menu-item a  {color: #d0021b;}
.main-navigation .sub-menu li.current_page_parent .sub-menu li.current-menu-item a  {color: #d0021b;}
.main-navigation .sub-menu li.current_page_ancestor .sub-menu li.current-menu-item a  {color: #d0021b;}

.main-navigation li.current-page-ancestor a, .main-navigation li.current-menu-ancestor a, .main-navigation li.current-menu-parent a, .main-navigation li.current-page-parent a, .main-navigation li.current_page_parent a, .main-navigation li.current_page_ancestor a {color: #d0021b;}

.main-navigation li.current-page-ancestor .sub-menu a, .main-navigation li.current-menu-ancestor .sub-menu a, .main-navigation li.current-menu-parent .sub-menu a, .main-navigation li.current-page-parent .sub-menu a, .main-navigation li.current_page_parent .sub-menu a, .main-navigation li.current_page_ancestor .sub-menu a {color: #fff;}

.main-navigation li .sub-menu a, .main-navigation li.current-page-ancestor .sub-menu a, .main-navigation li.current-menu-ancestor .sub-menu a, .main-navigation li.current-menu-parent .sub-menu a, .main-navigation li.current-page-parent .sub-menu a, .main-navigation li.current_page_parent .sub-menu a, .main-navigation li.current_page_ancestor .sub-menu a {color: #fff;}

.main-navigation li.current-page-ancestor .sub-menu li.current-page-ancestor a, .main-navigation li.current-menu-ancestor .sub-menu li.current-menu-ancestor a, .main-navigation li.current-menu-parent .sub-menu li.current-menu-parent a, .main-navigation li.current-page-parent .sub-menu li.current-page-parent a, .main-navigation li.current_page_parent .sub-menu li.current_page_parent a, .main-navigation li.current_page_ancestor .sub-menu li.current_page_ancestor a {color: #d0021b;}

.main-navigation li.current-menu-item a, .main-navigation li.current_page_item a {color: #d0021b;}
.main-navigation li.current-menu-item .sub-menu li a, .main-navigation li.current_page_item .sub-menu li a {color: #fff;}
.main-navigation li.current-menu-item .sub-menu li a:hover, .main-navigation li.current_page_item .sub-menu li a:hover {color: #d0021b;}




/*Sub menu*/
.sub-menu { position: absolute; left: 0; top: 100%; background-color: #373737; z-index: 9; opacity: 0; visibility: hidden; width: 235px; padding: 10px 0; list-style: none; padding: 0;}
.sub-menu li{list-style: none; text-align: left;margin: 0;}
.sub-menu li:last-child a {border: none;}
.sub-menu li > a {border-bottom: 1px solid rgba(255, 255, 255, 0.2);color: #fff;display: block;float: left;font-size: 18px;padding: 10px 22px;width: 100%;}
.sub-menu li a:hover {color: #d0021b !important;}
.main-navigation ul li.open > ul { visibility: visible; opacity: 1; }

.extra-menu {display: none;}


.call-email {margin-top: 14px;padding-left: 21px;}
.call-num i, .email-info i {display: none;}
a.call-num {color: #fff;font-size: 29px;font-family: 'basic-sans';line-height: 35px;}
a.call-num:hover {color: #d0021b;}
a.email-info {color: #9B9B9B;font-size: 21px;font-family: 'brandon-grotesque';}




/*================================================================
# Footer
================================================================*/
.site-footer {background-color: #0F3C70;padding: 54px 0 50px;}

.credentials-section {background-color: #0f3c70;color: #ffffff;display: inline-block;text-align: center;width: 100%;}
.credentials-section h2 {color: #ffffff;font-family: "basic-sans";font-size: 38px;margin-bottom: 20px;margin-top: 0;}
.credentials-section ul {padding: 0;margin: 0;}
.credentials-section ul li {display: inline-block;list-style: none;margin: 0 10px;max-width: 180px;position: relative;}
.credentials-section ul li:after {color: rgba(255, 255, 255, 0.1);content: "\f111";font-family: fontawesome;font-size: 50px;left: 50%;position: absolute;top: 37%;-webkit-transform: translateY(-50%) translateX(-50%);transform: translateY(-50%) translateX(-50%);}

.credentials-wrapper {border-bottom: 2px solid rgba(255, 255, 255, 0.2);padding-bottom: 62px;margin: 0 15px;

    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
    -moz-box-align: center;
    -webkit-align-items: center;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;

    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    -webkit-flex-direction: row;

    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -moz-box-pack: center;
    -webkit-justify-content: center;
}
.credentials-list {list-style: none;margin: 0 15px;max-width: 173px;position: relative;}
.credentials-list:after {color: rgba(255, 255, 255, 0.1);content: "\f111";font-family: fontawesome;font-size: 68px;left: 50%;position: absolute;top: 50%;-webkit-transform: translateY(-50%) translateX(-50%);transform: translateY(-50%) translateX(-50%);}
.credentials-list p {font-family: "brandon-grotesque";font-size: 16px;line-height: 23px;font-weight: normal;margin: 0;}

.contact-section {background-color: #0f3c70;float: left;padding-top: 27px;width: 100%;padding-bottom: 128px;}
.contact-section h2 {color: #ffffff;font-family: "basic-sans";font-size: 38px;margin-bottom: 16px;}
.contact-section p {font-family: "brandon-grotesque";font-size: 22px;color: #fff;}
.contact-form {padding-right: 50px;}
.contact-form .wpcf7-form-control-wrap {display: inline-block;margin-bottom: 15px;width: 100%;float: left;}
.contact-form .wpcf7-form-control-wrap input, .contact-form .wpcf7-form-control-wrap textarea {border: 1px solid transparent;border-radius: 0;padding: 9px 21px;width: 100%;color: #000000;font-size: 18px;display: block;}
.contact-form .wpcf7-form-control-wrap textarea {max-height: 117px;}
.contact-form input[type="submit"] {border: medium none;border-radius: 0;box-shadow: none;color: #ffffff;font-family: "brandon-grotesque";font-size: 22px;font-style: normal;letter-spacing: normal;padding: 16px 52px 12px 54px;position: relative;-webkit-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;white-space: normal;background-color: #d0021b;}
.contact-form input[type="submit"]:hover {background-color: #ff3a53;}
.contact-form .wpcf7-form {display: inline-block;margin-top: 14px;padding-right: 18px;}
.contact-form .wpcf7-form .col-sm-12 {padding: 0;}
.contact-form .wpcf7-form div.wpcf7-mail-sent-ng {float: left;width: 100%;color: #ff0000;margin-left: 0;text-align: left;padding-left: 0;border: none;margin-top: 10px;}
.contact-form .wpcf7-form div.wpcf7-response-output {float: left;width: 100%;color: #ff0000;margin-left: 0;text-align: left;padding-left: 0;border: none;margin-top: 10px;}
div.wpcf7-mail-sent-ok {border: none;color: #398f14 !important;}
.contact-submit {position: relative;display: inline-block;width: 100%;}
div.wpcf7 .ajax-loader {background-image:url("../media/loader.svg");background-repeat: no-repeat;background-size: 20px auto;height: 21px;width: 21px;left: 162px;position: absolute;top: 50%;-webkit-transform: translateY(-50%) translateX(-50%);transform: translateY(-50%) translateX(-50%);}

/*input prefix*/
::-webkit-input-placeholder {
color: #000;
opacity: 1;
}
:-moz-placeholder { /* Firefox 18- */
color: #000;
opacity: 1;
}
::-moz-placeholder {  /* Firefox 19+ */
color: #000;
opacity: 1;
}
:-ms-input-placeholder {
color: #000;
opacity: 1;
}


.testimonials p {font-style: italic;font-weight: 400;position: relative;z-index: 1;}
.testimonials p:before {background-image: url("../media/quote.png");background-repeat: no-repeat;background-size: 28px auto;content: "";height: 50px;left: -17px;position: absolute;top: -5px;width: 50px;z-index: -1;}
.testimonials span {color: #ffffff;display: block;font-family: "brandon-grotesque";font-size: 22px;font-weight: 700;text-align: right;padding-right: 1px;}
.testimonials .btn-default {margin-top: 24px;padding: 14px 53px 13px 52px;}

.copyright {bottom: -158px;max-width: 345px;position: absolute;}
.contact-section .copyright p {color: #9B9B9B;font-size: 16px;}
.copyright p a:first-child {margin: 0;}
.copyright p a {color: #9b9b9b;margin-left: 12px;}
.copyright p a:hover {color: #d0021b;}



/*------------ SERVICE SECTION -----------*/
.service-section .container-fluid {padding: 0;}
.service-section {padding: 14px 0 20px 0;}
.service-item-wrapper {float: left;max-height: 394px;overflow: hidden;position: relative;text-align: center;-webkit-transition: all 0.3s ease-in-out 0s;transition: all 0.3s ease-in-out 0s;width: 100%;}
.service-item { background-size: cover; background-position: center center; background-repeat: no-repeat;}
.service-item img { opacity: 0; }
.service-item:before { background-color: rgba(15,60,112,0.6);content: "";height: 100%;left: 0;position: absolute;top: 0;width: 100%; }
.service-item h2 {color: #ffffff;font-family: "basic-sans";font-size: 38px;font-style: normal;left: 50%;line-height: 42px;margin: 9px 0 0;padding: 0 50px;position: absolute;top: 50%;-webkit-transform: translateY(-50%) translateX(-50%);transform: translateY(-50%) translateX(-50%);width: 100%;}
.service-item h2 a {color: #fff;}
.service-hover-content { position: absolute; top: 100%; left: 0; right: 0; height: 100%; -webkit-transition: all 0.3s ease-in-out 0s;transition: all 0.3s ease-in-out 0s;}
.service-item:hover > .service-hover-content,.service-item.hover_effect > .service-hover-content{ top: 0; }
.service-item:hover > h2 { opacity: 0; }
.service-item.hover_effect > h2 { opacity: 0; }
.service-hover-content a { display: block;width: 100%;height: 100%;position: relative;text-align: center;background-color: rgba(208,2,27,0.8); }
.service-hover-content a h2 {margin-bottom: 9px;margin-top: 11px;opacity: 1;position: static;-webkit-transform: none;transform: none;}
.overlay-content { position: absolute; top: 50%; -webkit-transform: translateY(-50%); transform: translateY(-50%);height: 0;width: 100%;}
.service-item:hover > .service-hover-content .overlay-content {height: auto;}
.service-item.hover_effect > .service-hover-content .overlay-content {height: auto;}
.service-section .service-box {display: inline-block;margin-bottom: 23px;padding: 0 11px;}

.service-hover-content p {color: #ffffff;font-size: 16px;line-height: 21px;padding: 0 20px;text-align: center;z-index: 1;}


/*------------ ABOUT SECTION -----------*/
.about-content-section {padding: 56px 0 88px;}
.about-content {padding-right: 100px;}
.about-content p {font-family: "brandon-grotesque";font-size: 22px;font-weight: 500;}
.about-content .btn-default {margin-top: 19px;padding: 14px 41px;}
.about-image > img {float: right;margin-top: -16px;max-height: 323px;max-width: inherit;}
.about-image {float: right;}




.service-item:hover .service-content {height: auto;position: absolute;top: 41%;-webkit-transform: translateY(-50%);transform: translateY(-50%);-webkit-transition: all 0.5s ease 0s;transition: all 0.5s ease 0s;width: 100%;}
.service-contect-desc {opacity: 0;position: absolute;padding: 0 40px;}
.service-contect-desc p {color: #ffffff;font-size: 18px;font-family: "brandon-grotesque";}
.service-item:hover .service-contect-desc {opacity: 1;position: static;}
.service-item:hover h2 {margin-bottom: 11px;margin-top: 0;position: static;-webkit-transform: none;transform: none;}
.service-item:hover:before {background-color: rgba(208,2,27,0.8);}
.learn-more-link {bottom: -56px;left: 50%;position: absolute;-webkit-transform: translateX(-50%);transform: translateX(-50%);}
.service-item:hover i {color: rgba(255, 255, 255, 0.5);font-size: 46px;}
.service-item:hover span {display: none;}


/*================================================================
# Inner page
================================================================*/
.inner-page-content {padding: 48px 0 76px;}
.inner-page-content h2 {margin-top: 23px;}
.inner-page-content p {margin-bottom: 8px;margin-top: 20px;width: 100%;}
.inner-page-content .contact-form .wpcf7-form {background: #0f3c70; padding: 20px;}
.inner-left-content img {margin-bottom: 10px;margin-top: 10px;}
.inner-left-content .btn-default {margin-top: 30px;padding: 14px 54px 13px 52px;}

.sidebar {margin-top: 28px;padding-left: 27px;}
.sidebar ul {padding: 0;margin: 0;}
.sidebar ul li {list-style: none;margin-bottom: 19px;padding-left: 25px;position: relative;}
.sidebar ul li:before {content: "\f054";font-family: fontawesome;left: 0;position: absolute;top: -1px;color: #0f3c70;}
.sidebar ul li:hover:before {color: #d0021b;}
.sidebar ul li:hover a {color: #d0021b;}
.sidebar ul li a {color: #0f3c70;display: block;font-family: "basic-sans";font-size: 18px;line-height: 23px;}
.sidebar li.active a {color: #d0021b;}
.sidebar li.active:before {color: #d0021b;}

/* Blocks */
.container.blocks .col-sm-4 {background: #e1e1e1;height:200px;margin: 15px;max-width: 29%;position: relative;}
.container.blocks .col-sm-4 img {left: 50%;margin-top: 0;padding: 0 15px;position: absolute;top: 50%;transform: translate(-50%, -50%)}
