@media screen and (max-width: 1920px) {
    .service-item:hover .service-content {top: 45%;}
    .service-item:hover h2 {margin-bottom: 5px;}
    .service-contect-desc {padding: 0 35px;}
    .learn-more-link {bottom: -43px;}
}

@media screen and (max-width: 1800px) {
    .service-item h2 {font-size: 34px;line-height: 34px;padding: 0 10px;}
    .service-contect-desc {padding: 0 20px;}
    .service-contect-desc p {font-size: 16px;line-height: 18px;}
    .learn-more-link {bottom: -42px;}
    .service-item:hover i {font-size: 40px;}
}

@media screen and (max-width: 1590px) {
    .service-item h2 {font-size: 29px;line-height: 30px;}
}

@media screen and (max-width: 1440px) {
    body {font-size: 20px;}
    .btn {font-size: 20px;}
    .site-footer {padding: 40px 0 50px;}
    .logo {max-width: 415px;}
    .logo h1 {font-size: 36px;}
    .logo p {font-size: 18px;}
    .menu > li > a {font-size: 20px;padding: 0 17px 40px;}
    .sub-menu li > a {padding: 10px 16px;}
    .call-email {padding-left: 0;}
    a.call-num {font-size: 26px;line-height: 32px;}
    a.email-info {font-size: 18px;}
    .service-item h2 {font-size: 24px;line-height: 26px;padding: 0 15px;}
    .service-contect-desc {padding: 0 15px;}
    .service-contect-desc p {font-size: 16px;line-height: 18px;}
    .service-item:hover i {font-size: 34px;}
    .learn-more-link {bottom: -40px;}

    .credentials-list {margin: 0 9px;max-width: 178px;}
    .credentials-list p {font-size: 14px;}

    .about-content-section {padding: 30px 0 50px;}
    .about-image > img {max-height: 260px;}
    .about-content p {font-size: 20px;}
    .contact-section p {font-size: 20px;}
}

@media screen and (max-width: 1350px) {
    .service-item:hover .service-content {top: 41%;}
    .service-item h2 {font-size: 22px;line-height: 24px;}
    .service-contect-desc p {font-size: 14px;line-height: 16px;}
    .learn-more-link {bottom: -36px;}
    .service-item:hover i {font-size: 30px;}
}

@media screen and (max-width: 1199px) {
    body {font-size: 16px;}
    .site-footer, .site-content, .site-header {position: relative;}
    .site-content {margin-bottom: 0;}

    h1, .h1 {font-size: 44px;}
    h2, .h2 {font-size: 32px;}
    h3, .h3 {font-size: 26px;}
    h4, .h4 {font-size: 24px;}
    h5, .h5 {font-size: 22px;}
    h6, .h6 {font-size: 20px;}

    .btn {font-size: 18px;}
    .header-logo {padding-left: 15px;width: 41%;}
    .logo {max-width: 369px;}
    .logo h1 {font-size: 32px;}
    .header-nav {width: 43%;}
    .header-call-info {width: 16%;}
    .menu > li > a {font-size: 18px;padding: 0 13px 35px;}
    .sub-menu li > a {font-size: 16px;padding: 10px 11px;}
    .sub-menu {width: 205px;}
    a.call-num {font-size: 22px;}
    a.email-info {font-size: 16px;}

    .service-item h2 {font-size: 19px;line-height: 20px;}
    .service-contect-desc p {font-size: 14px;line-height: 15px;}
    .learn-more-link {bottom: -32px;}

    .about-content p {font-size: 16px;}
    .about-content .btn-default {padding: 11px 30px;}
    .about-image > img {max-height: 240px;margin-top: -10px;}

    .credentials-section h2 {font-size: 34px;}
    .site-footer {padding: 30px 0 50px;}
    .credentials-wrapper {padding-bottom: 44px;}
    .contact-section h2 {font-size: 34px;}
    .contact-section {padding-top: 20px;}
    .contact-section p {font-size: 18px;}
    .testimonials span {font-size: 20px;}
    .copyright {max-width: 320px;}
    .contact-section .copyright p {font-size: 14px;}
    .testimonials .btn-default {padding: 12px 49px 11px 48px;}
    .contact-form input[type="submit"] {font-size: 18px;padding: 12px 48px 8px 50px;}
    div.wpcf7 .ajax-loader {left: 150px;}

    .sidebar {padding-left: 0;}
    .sidebar ul li {padding-left: 22px;}
    .sidebar ul li:before {font-size: 17px;top: 0;}
    .sidebar ul li a {font-size: 17px;}
    .inner-page-content {padding: 8px 0 36px;}
}

@media (min-width: 768px) {
    .container {width: 755px}
}
@media (min-width: 992px) {
    .container {width: 975px}
}
@media (min-width: 1200px) {
    .container {width: 1195px}
}
@media (min-width: 1440px) {
    .container {width: 1440px}
}

@media screen and (max-width: 1026px) {
    body {font-size: 16px;}
    .service-section .service-box {margin-bottom: 15px;padding: 0 8px;}
    .about-content p {font-size: 16px;}
    .service-item h2 {margin: 0;}
    .service-item:hover h2 {margin: 0;position: absolute;-webkit-transform: translateY(-50%) translateX(-50%);transform: translateY(-50%) translateX(-50%);}
    .service-item:hover .service-contect-desc {opacity: 0;}
    .service-item:hover .service-content {position: static;-webkit-transform: none;transform: none;}
    .testimonials p:before {background-size: 21px auto;left: -12px;top: -3px;}
}

@media screen and (max-width: 991px) {

    .header-logo {width: 36%;}
    .logo h1 {font-size: 22px;}
    .logo p {font-size: 14px;}
    .logo p span:after {height: 1px;}
    .header-nav {width: 46%;}
    .main-navigation {margin-top: 20px;}
    .menu > li > a {font-size: 14px;padding: 0 11px 25px;}
    .main-navigation li.menu-item-has-children .sub-menu li a {font-size: 14px;}
    a.call-num {font-size: 16px;line-height: 28px;}
    a.email-info {font-size: 14px;}
    .call-email {margin-top: 0;}

    .about-content-section {padding: 20px 0 40px;}
    .about-content .btn-default {padding: 9px 20px;}
    .about-image > img {margin-top: -5px;max-height: 200px;}
    .credentials-section h2 {font-size: 32px;}
    .credentials-wrapper {padding-bottom: 22px;}
    .credentials-list {margin: 0 7px;}
    .contact-section {padding-top: 12px;}
    .contact-section h2 {font-size: 32px;}
    .contact-section p {font-size: 16px;line-height: 22px;}
    .credentials-list p {word-break: break-all;}
    .contact-form {padding-right: 10px;}
    .contact-form .wpcf7-form-control-wrap input, .contact-form .wpcf7-form-control-wrap textarea {padding: 8px 17px;}
    .contact-form input[type="submit"] {padding: 10px 44px 8px 46px;}
    .testimonials span {font-size: 16px;}
    .testimonials .btn-default {padding: 11px 45px 10px 44px;}
    div.wpcf7 .ajax-loader {left: 140px;}

    .inner-left-content .btn-default {display: block;}
    .inner-left-content img {margin-top: 0;margin-bottom: 0;}
	
	.row div[class^="col-md-"]:not(:first-child) ol {margin-top: -18px;}
	.row div[class^="col-md-"]:not(:first-child) ul {margin-top: -18px;}
}

@media (max-width:767px) {
    h1, .h1 {font-size: 40px;}
    h2, .h2 {font-size: 28px;}
    h3, .h3 {font-size: 24px;}
    h4, .h4 {font-size: 22px;}
    h5, .h5 {font-size: 20px;}
    h6, .h6 {font-size: 18px;}

    .container-fluid {padding-left: 20px;padding-right: 20px;}
    .container {padding-left: 20px;padding-right: 20px;}

	.alignleft {display: block;float: none;margin: 0 auto 15px;text-align: center;}
    .aligncenter {display: block;float: none;margin: 0 auto 15px;text-align: center;}
    .alignright {display: block;float: none;margin: 0 auto 15px;text-align: center;}

	.header-logo {width: 100%;padding: 4px 17px 0;text-align: center;}
    .logo {min-width: 277px;width: 277px;display: inline-block;text-align: center;}
	.logo h1 {font-size: 24px;}
	.logo p {font-size: 15px;}
    .call-num i, .email-info i {display: block;}
    .header-navigation {bottom: -20px;position: absolute;width: 100%;}
    .call-email {padding-left: 15px;margin-top: 0;}
	a.call-num {background-color: #383838;border-radius: 50%;display: inline-block;font-size: 0;height: 40px;margin-right: 20px;position: relative;text-align: center;vertical-align: middle;width: 40px;}
	.call-num i {font-size: 24px;left: 50%;position: absolute;top: 50%;-webkit-transform: translateY(-50%) translateX(-50%);transform: translateY(-50%) translateX(-50%);}
	.extra-menu a.email-info {background-color: #383838;border-radius: 50%;display: inline-block;font-size: 0;height: 40px;position: relative;text-align: center;vertical-align: middle;width: 40px;color: #ffffff;}
	.email-info i {font-size: 24px;left: 50%;position: absolute;top: 50%;-webkit-transform: translateY(-50%) translateX(-50%);transform: translateY(-50%) translateX(-50%);}
	.header-nav {width: 100%;}
	.header-call-info {width: 100%;}
	.site-header {-webkit-transition: all 0.5s ease-in-out 0s;transition: all 0.5s ease-in-out 0s;overflow: visible;z-index: 1;}
    .site-header .site-branding {padding-left: 32px; padding-bottom: 20px;}
    .site-footer, .site-content {-webkit-transition: all 0.5s ease-in-out 0s;transition: all 0.5s ease-in-out 0s;}
    .site-footer, .site-content, .site-header {position: relative;}
    .site{ -webkit-transition: all 0.5s ease-in-out 0s; transition: all 0.5s ease-in-out 0s; position: relative; right: 0; }
	.open-menu .site {right: 220px; }
	a.menu-icon {background-color: #383838;border-radius: 50%;display: block !important;height: 40px;position: absolute;right: 140px;top: auto;width: 40px;z-index: 9;}
    .open-menu a.menu-icon i:before {content: "\f00d";}
    .site-header { width: 100%; height: 99px;}

	/*Navigation*/

    /*touch no touch*/
    .touch .main-navigation .menu li.open:after a{ content: "\f106"; width: auto;}
    /********** Menu Toggle ***********/
    .main-navigation {background-color: #373737;height: 100%;right: -220px;padding-top: 0;position: fixed;top: 0;-webkit-transition: all 0.5s ease-in-out 0s;transition: all 0.5s ease-in-out 0s; width: 220px;padding: 0;z-index: 99;overflow: auto;margin: 0;}
    .open-menu .main-navigation{ right: 0;}
    .main-navigation .menu {margin: 0;}
    .main-navigation .menu li {border-bottom: 1px solid rgba(255, 255, 255, 0.2);display: block;float: left;margin: 0;padding: 0;text-align: left;width: 100%;}
    .main-navigation .menu li a {color: #ffffff;display: inline-block;padding: 15px;position: relative;width: 100%;background-color: transparent;border-radius: 0;}
    .main-navigation .menu li a:hover {color: #d0021b;}
    .menu li.menu-item-has-children .sub-menu li:first-child {border-top: 1px solid rgba(255, 255, 255, 0.2);}
    .menu li.menu-item-has-children .sub-menu li:last-child {border-bottom: none;}
    .main-navigation ul {display: block;overflow: hidden;width: 100%;}

    .main-navigation .sub-menu li.menu-item-has-children a::after {display: none;}
    .login-link {display: none;}
    .menu-icon i {color: #fff;font-size: 24px;left: 50%;position: absolute;top: 50%;-webkit-transform: translate(-50%, -50%);transform: translate(-50%, -50%);}

    .sub-menu {background-color: transparent;box-shadow: none;width: 100%;display: none;}
    .menu-item-has-children.open .sub-menu {display: block;float: left;min-width: auto;padding: 0;}

    .main-navigation li.menu-item-has-children .sub-menu li a {color: #fff;padding: 15px 25px;border: none;height: auto;}
	.main-navigation li.menu-item-has-children .sub-menu li a:hover {color: #d0021b;}
    .main-navigation li.menu-item-has-children .sub-menu li.menu-item-has-children .sub-menu li a {color: #fff;padding: 15px 35px;}

    .main-navigation .sub-menu {display: none;}
    .menu-container-inner .menu span.plus {position: relative;}
    .main-navigation .sub-menu li.menu-item-has-children:hover .sub-menu {display: none;}

    .menu-item-has-children span.menu-arrow {position: relative;display: block;}

    .menu-item-has-children span.menu-arrow:before {color: #ffffff;content: "\f107";font-family: FontAwesome;font-size: 21px;font-weight: normal;height: 30px;position: absolute;right: 0;top: -37px;width: 30px;cursor: pointer;}
    .menu-item-has-children.open span.menu-arrow:before {color: #ffffff;content: "\f106";font-family: FontAwesome;font-size: 21px;font-weight: normal;height: 30px;position: absolute;right: 0;top: -37px;width: 30px;cursor: pointer;}
    .menu-item-has-children:hover span.menu-arrow:before {color: #d0021b;}
    .menu-item-has-children.open:hover span.menu-arrow:before {color: #fff;}
    .menu-item-has-children.open:hover .menu-item-has-children:hover span.menu-arrow:before {color: #d0021b;}

    .menu-item-has-children.open .sub-menu {display: block;position: relative;}
    .menu-item-has-children.open .sub-menu .sub-menu {display: none;}

    .menu-item-has-children.open .sub-menu .menu-item-has-children.open .sub-menu {display: block;left: 0;position: relative;top: 0;}

    .menu-item-has-children .sub-menu .menu-item-has-children:hover .sub-menu {display: none;}
    .menu-item-has-children.open .sub-menu .menu-item-has-children .sub-menu {display: none;}
    .main-navigation .sub-menu li.menu-item-has-children:hover .sub-menu li a {color: #fff;background-color: #0c0c0c;}
    .main-navigation .sub-menu li.menu-item-has-children:hover .sub-menu li a:hover {color: #d0021b;}
    .menu-item-has-children .menu-item-has-children span.menu-arrow::before {display: none;}
    .menu-item-has-children.open .menu-item-has-children.open span.menu-arrow::before {display: block;}

    .menu-item-has-children.open .menu-item-has-children.open .sub-menu span.menu-arrow::before {display: none;}

    .menu-item-has-children.open li span.menu-arrow::before {display: none;}
    .menu-item-has-children.open li.menu-item-has-children span.menu-arrow::before {display: block;content: "\f107";}
    .menu-item-has-children.open li.menu-item-has-children.open span.menu-arrow::before {content: "\f106";}

    .menu li.menu-item-has-children .sub-menu {padding-top: 0;}
	.main-navigation .menu li.current-menu-item a, .main-navigation .menu li.current_page_item a {color: #d0021b;}
	.main-navigation .menu li.current-menu-item .menu-arrow:before, .main-navigation .menu li.current_page_item .menu-arrow:before {color: #d0021b;}
	.main-navigation .menu li.current-page-ancestor a, .main-navigation .menu li.current-menu-ancestor a, .main-navigation .menu li.current-menu-parent a, .main-navigation .menu li.current-page-parent a, .main-navigation .menu li.current_page_parent a, .main-navigation .menu li.current_page_ancestor a {color: #d0021b;}
	.main-navigation .menu li.current-page-ancestor .sub-menu li.current-menu-item a, .main-navigation .menu li.current-page-ancestor .sub-menu li.current_page_item a {color: #d0021b;}
	.main-navigation .menu li.current-menu-ancestor .sub-menu li.current-menu-item a, .main-navigation .menu li.current-menu-ancestor .sub-menu li.current_page_item a {color: #d0021b;}
	.main-navigation .menu li.current-menu-parent .sub-menu li.current-menu-item a, .main-navigation .menu li.current-menu-parent .sub-menu li.current_page_item a {color: #d0021b;}
	.main-navigation .menu li.current-page-parent .sub-menu li.current-menu-item a, .main-navigation .menu li.current-page-parent .sub-menu li.current_page_item a {color: #d0021b;}
	.main-navigation .menu li.current_page_parent .sub-menu li.current-menu-item a, .main-navigation .menu li.current_page_parent .sub-menu li.current_page_item a {color: #d0021b;}
	.main-navigation .menu li.current_page_ancestor .sub-menu li.current-menu-item a, .main-navigation .menu li.current_page_ancestor .sub-menu li.current_page_item a {color: #d0021b !important;}

	.main-navigation .menu li.current-page-ancestor .menu-arrow:before, .main-navigation .menu li.current-menu-ancestor .menu-arrow:before, .main-navigation .menu li.current-menu-parent .menu-arrow:before, .main-navigation .menu li.current-page-parent .menu-arrow:before, .main-navigation .menu li.current_page_parent .menu-arrow:before, .main-navigation .menu li.current_page_ancestor .menu-arrow:before {color: #d0021b;}

	.main-navigation .menu li.current-page-ancestor .sub-menu li .menu-arrow:before, .main-navigation .menu li.current-menu-ancestor .sub-menu li .menu-arrow:before, .main-navigation .menu li.current-menu-parent .sub-menu li .menu-arrow:before, .main-navigation .menu li.current-page-parent .sub-menu li .menu-arrow:before, .main-navigation .menu li.current_page_parent .sub-menu li .menu-arrow:before, .main-navigation .menu li.current_page_ancestor .sub-menu li .menu-arrow:before {color: #fff;}

	.main-navigation .menu li.current-page-ancestor .sub-menu li.current-page-ancestor .menu-arrow:before, .main-navigation .menu li.current-menu-ancestor .sub-menu li.current-menu-ancestor .menu-arrow:before, .main-navigation .menu li.current-menu-parent .sub-menu li.current-menu-parent .menu-arrow:before, .main-navigation .menu li.current-page-parent .sub-menu li.current-page-parent .menu-arrow:before, .main-navigation .menu li.current_page_parent .sub-menu li.current_page_parent .menu-arrow:before, .main-navigation .menu li.current_page_ancestor .sub-menu li.current_page_ancestor .menu-arrow:before {color: #d0021b;}

    .main-navigation .menu li .sub-menu li.menu-item-has-children .menu-arrow:before {color: #fff;}


    .service-section {background-color: #f1f1f1;padding: 49px 0 12px;}
    .service-item .img-cover {display: none;}
    .service-item h2 {color: #0F3C70;padding: 0;position: static;text-align: left;-webkit-transform: none;transform: none;cursor: pointer;font-size: 18px;}
    .service-content {position: static;-webkit-transform: none;transform: none;padding-left: 28px;}
    .service-contect-desc {margin-top: 10px;opacity: 1;padding: 0;position: static;}
    .service-item:hover .service-content {position: static;-webkit-transform: none;transform: none;}
    .service-item::before {content: none;}
    .service-contect-desc p  {color: #D0021B;padding: 0;line-height: 18px;margin-bottom: 5px;}
    .learn-more-link {position: static;margin-left: -1px;}

    .service-item:hover h2 {margin-top: 0;position: static;-webkit-transform: none;transform: none;}
    .service-item .link-over {position: static;}
    .service-item:hover .service-contect-desc {opacity: 1;}

    .service-item-wrapper {text-align: left;}
    .service-content h2.active .service-contect-desc {display: block;}
    .service-item h2.active {color: #D0021B;}
    .service-contect-desc {display: none;}

    .service-item h2 a {color: #d0021b;}
    .service-item i {color: #d0021b;display: inline-block;font-size: 21px;vertical-align: middle;margin-right: 12px;}
    .service-item span {display: inline-block;vertical-align: middle;color: #d0021b;font-family: "Brandon Grotesque";text-transform: capitalize;font-size: 14px;}
    .service-item:hover i {font-size: 22px;color: #d0021b;}
    .service-content h2.active i, .service-item:hover i {color: #d0021b;display: inline-block;font-size: 21px;vertical-align: middle;}
    .service-content h2.active span, .service-item:hover span {display: inline-block;vertical-align: middle;}
    .service-content h2:before {content: "\f054";font-family: fontawesome;left: 0;position: absolute;top: 1px;width: 18px;text-align: center;}
    .service-content h2.active:before {content: "\f078";left: -1px;}

    .service-section .service-box {padding: 0 6px 0 3px;width: 100%;margin-bottom: 12px;}
    .service-section .container-fluid {padding-left: 20px;padding-right: 20px;}

    .about-content-section {padding: 35px 0 56px;}
    .about-image {text-align: center;float: none;}
    .about-image > img {float: none;margin-top: 0;max-height: inherit;width: 284px;}
    .about-content {margin-top: 26px;padding-right: 0;}
    .about-content p {padding: 0 4px;}
    .credentials-wrapper {display: block;}
    .credentials-list:after {content: none;}

    .about-content .btn-default {max-width: 280px;padding: 19px 26px 15px 25px;width: 100%;margin-top: 14px;}

    .site-footer {padding: 43px 0 27px;}
    .contact-section {padding-top: 11px;padding-bottom: 35px;}
    .credentials-wrapper {padding-bottom: 30px;}
    .credentials-section h2 {font-size: 26px;margin-bottom: 10px;}
    .credentials-list p {font-size: 13px;}
    .testimonials p:before {background-size: 28px auto;left: -8px;top: -10px;}
    .credentials-list {margin: 0 0 4px;max-width: 100%;}
    .contact-section h2 {font-size: 26px;text-align: center;margin-bottom: 14px;}
    .contact-form p {text-align: center;padding: 0 10px;}
    .contact-form .wpcf7-form {border-bottom: 2px solid rgba(255, 255, 255, 0.2);margin-bottom: 17px;margin-top: 19px;padding-bottom: 1px;padding-right: 0;width: 100%;}
    .contact-form .wpcf7-form-control-wrap input, .contact-form .wpcf7-form-control-wrap textarea {font-size: 15px;padding: 7px 11px 9px;}
    .contact-form .wpcf7-form-control-wrap {margin-bottom: 13px;}
    .contact-form input[type="submit"] {padding: 13px 44px 8px 46px;width: 100%;}
    div.wpcf7 .ajax-loader {display: block;margin: 11px auto;padding-top: 20px;position: static;text-align: center;-webkit-transform: none;transform: none;}
    .contact-section .testimonials h2 {margin-bottom: 25px}
    .contact-section .testimonials p {margin-bottom: 16px;}
    .testimonials .btn-default {margin-top: 17px;width: 100%;}
    .contact-form .wpcf7-form div.wpcf7-response-output {margin-top: -30px;}

    .copyright {position: static;max-width: 100%;text-align: center;margin-top: 40px;}
    .contact-section .copyright p {font-size: 12px;line-height: 18px;}
    .copyright p a {margin-left: 4px;}



    .header-navigation {display: none;}
    .text-menu {display: block;text-align: center;}


    .nav-icon {background-color: #383838;border-radius: 50%;display: inline-block;height: 49px;margin-right: 20px;position: relative;width: 49px;}
    .extra-menu ul li a.nav-icon i {color: #ffffff;font-size: 25px;top: 51%;}
    .open-menu a.nav-icon i:before {content: "\f00d";}
    .open-menu .extra-menu ul li a.nav-icon i {top: 47%;}

    .site-content {margin-top: -35px;}
    .extra-menu {display: inline-block;margin-top: 14px;padding-left: 36px;text-align: center;width: 100%;}
    .extra-menu ul {padding: 0;margin: 0;}
    .extra-menu ul li {padding: 0;margin: 0;list-style: none;display: inline-block;}
    .extra-menu ul li a {background-color: #383838;border-radius: 50%;display: inline-block;height: 49px;margin-right: 35px;position: relative;width: 49px;}
    .extra-menu ul li a i {color: #ffffff;font-size: 26px;left: 50%;position: absolute;top: 50%;-webkit-transform: translateX(-50%) translateY(-50%);transform: translateX(-50%) translateY(-50%);}
    .extra-menu ul li a i.fa-envelope-o {font-size: 21px;top: 47%;left: 49%;}

    .inner-page-content {padding: 31px 0 43px;}
    .inner-page-content h2 {font-size: 28px;line-height: 32px;padding-right: 1px;}
	.inner-page-content div[class^="col-sm-"]:not(:last-of-type) ul {margin-bottom: 0;}
    .inner-left-content p:first-of-type {margin-bottom: 8px;}
    .inner-page-content p {margin-top: 12px;margin-bottom: 17px;}
    .inner-left-content img {margin-bottom: 0;margin-top: 10px;}
    .inner-left-content .btn-default {font-size: 22px;margin-top: 12px;padding: 15px 54px 12px 52px;}

    .sidebar {display: none;}
	
	/* Blocks */
    .container.blocks .col-sm-4 {max-width: 100%; }

}
